import liff from "@line/liff";
import { SocialLineLoginModule } from "@/api/social/line/login";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import store from "@/store";
import { useRoute } from "vue-router";

export function socialLineLoginLiff() {
  if (process.env.NODE_ENV === "production") {
    const access_token = liff.getAccessToken();
    SocialLineLoginModule.post({ access_token: access_token }).then(
      async (response) => {
        JwtService.saveToken(response.data.token);
        await store.dispatch(Actions.VERIFY_AUTH, {
          token: JwtService.getToken(),
        });
      }
    );
  }
}
export async function initializeLiff() {
  if (process.env.NODE_ENV === "production") {
    try {
      const liffId = "2006156638-V42qjrYe"; // Replace with your actual LIFF ID
      console.log(`Initializing LIFF with ID: ${liffId}`);

      await liff.init({ liffId });
      console.log("LIFF initialized successfully.");

      if (liff.isLoggedIn()) {
        socialLineLoginLiff(); // Your login handling function
      } else {
        // const currentPath = window.location.href;
        // const url = new URL(currentPath);
        // const toParam = url.searchParams.get("to");
        // const redirectUri = `https://zhuoen.cloud/login/line/callback?to=${
        //   toParam || ""
        // }`;
        // console.log("Redirecting to LINE login with URI:", redirectUri);
        liff.login();
      }
    } catch (error) {
      console.error("LIFF initialization failed:");
      console.log(error);
    }
  }
}

export function liffIsInClient() {
  return liff.isInClient();
}
export default liff;
